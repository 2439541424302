import data from "./regulatoryItemsData.js";
export default {
	name: "regulatoryItems",
	data,
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 折叠面板打开
		handleChange(val) {
			// console.log(val);
		},
	},
	components: {
		previewChart
	},
	beforeDestroy() {},
	watch: {}
}
