export default () => {
  return {
		activeNames: ['1'],
		screeningUser: '',// 筛选用户名
		UserOptions: [],// 筛选用户列表
		screeningCompany: '',// 筛选公司名
		companyOptions: [],// 筛选公司列表
		displayData: [],// 左边折叠面板项目展示数据
		displaySelect: [],// 左边折叠面板项目展示数据选中数据
		projectList: [],// 右边项目选择列表展示数据
		
		
		preview: false,// 测试
		fileBaseData: null,
	}
}
