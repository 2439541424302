<template>
	<div class="regulatoryItems">
		<div class="list-box">
			<div class="heard">
				<el-select v-model="screeningUser" placeholder="请选择">
					<el-option v-for="item in UserOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="scroll-box">
				<vue-scroll>
					<el-collapse v-model="activeNames" @change="handleChange">
						<el-collapse-item title="一致性 Consistency" name="1">
							<div class="content-box">
								<div class="left">
									<div class="scroll-box">
										<el-checkbox-group v-model="displaySelect">
											<!-- displayData -->
										    <el-checkbox-button v-for="(i,index) in 5" :label="i" :key="index">{{i}}</el-checkbox-button>
										</el-checkbox-group>
									</div>
								</div>
								<div class="right">
									<el-button size="small" type="primary">保存</el-button>
									<el-button size="small" type="warning" class="margin">删除</el-button>
									<el-button size="small" type="danger">清空</el-button>
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</vue-scroll>
			</div>
		</div>
		<div class="select-box">
			<div class="heard">
				<el-select v-model="screeningCompany" placeholder="请选择">
					<el-option v-for="item in companyOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="scroll-cell-box">
				<vue-scroll>
					<p class="cell-p" @click="">1233</p>
				</vue-scroll>
			</div>
		</div>
	</div>
</template>

<script>
import regulatoryItems from './regulatoryItems.js';
export default regulatoryItems;
</script>

<style scoped lang="scss">
@import './regulatoryItems.scss';
</style>
